import React from "react";
import { Container, Navbar, Nav, Row, Col, Button, Card, Accordion, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const LandingPage = () => {
  return (
    <Container fluid className="landing-page">
      {/* Navbar */}
      <Navbar bg="white" expand="lg" className="py-3 px-md-5">
        <Container>
          <Navbar.Brand href="#" className="d-flex align-items-center">
            <img src="/assets/oasee-logo.png" alt="Oasee Logo" style={{ width: '60px' }} className="me-3" />
            <Nav className="d-none d-lg-flex">
              <Nav.Link href="#home" className="text-dark">Home</Nav.Link>
              <Nav.Link href="#features" className="text-dark">Features</Nav.Link>
              <Nav.Link href="#faqs" className="text-dark">FAQs</Nav.Link>
            </Nav>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto d-lg-none">
              <Nav.Link href="#home" className="text-dark">Home</Nav.Link>
              <Nav.Link href="#features" className="text-dark">Features</Nav.Link>
              <Nav.Link href="#faqs" className="text-dark">FAQs</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Hero Section */}
      <Row className="hero align-items-center py-0 px-md-5">
        <Col md={6} className="text-center text-md-start">
          <h1 className="display-4 fw-bold">
            Set Goals & <span className="text-primary">Make Your Dream</span> Come True
          </h1>
          <p className="lead">
            Start, Switch Or Advance Your Career With More The 500 Courses
          </p>
          <div className="d-flex justify-content-center justify-content-md-start gap-3 mt-4">
            <Button variant="light" className="p-0 border-0">
              <img src="/assets/playstore.png" alt="Google Play" className="img-fluid" />
            </Button>
            <Button variant="light" className="p-0 border-0">
              <img src="/assets/appstore.png" alt="App Store" className="img-fluid" />
            </Button>
          </div>
        </Col>
        <Col md={6} className="text-center mt-4 mt-md-0">
          <img src="/assets/hero.png" alt="App Mockup" className="img-fluid" />
        </Col>
      </Row>

      {/* Features Section */}
      <Row className="features align-items-center py-5 px-md-5">
        <Col md={6} className="text-center mt-4 mt-md-0">
          <img src="/assets/mid.png" alt="Feature Mockup" className="img-fluid" />
        </Col>
        <Col md={6} className="text-center text-md-start">
          <h2 className="text-primary fw-bold mb-3">FEATURES</h2>
          <h3 className="display-5 fw-bold">Free Live Classes</h3>
          <div className="mt-4">
            <h5 className="fw-bold">✨ Live Classes</h5>
            <p>
              Cum Et Convallis Risus Placerat Aliquam, Nunc. Scelerisque Aliquet Faucibus
              Tincidunt Eu Adipiscing Sociis Arcu Lorem Porttitor.
            </p>
          </div>
          <div className="mt-3">
            <h5 className="fw-bold">✨ Hundreds Of Courses</h5>
            <p>
              Cum Et Convallis Risus Placerat Aliquam, Nunc. Scelerisque Aliquet Faucibus
              Tincidunt Eu Adipiscing Sociis Arcu Lorem Porttitor.
            </p>
          </div>
        </Col>
      </Row>

      {/* Testimonial Section */}
      <Row className="testimonial text-center py-5 px-md-5">
        <Col>
          <h2 className="text-primary fw-bold mb-3">TESTIMONIAL</h2>
          <h3 className="display-5 fw-bold">What Our Users Say About Us?</h3>
        </Col>
      </Row>
      <Row className="justify-content-center py-3 px-md-5">
        <Col md={5} className="mb-4">
          <Card className="shadow border-0">
            <Card.Body>
              <Card.Text>
                Fint has transformed the way I manage my finances. Real-time data, insightful advice, and user-friendly design make it a must-have tool for anyone serious about their financial well-being.
              </Card.Text>
              <div className="d-flex align-items-center mt-4">
                <img
                  src="/assets/avatar.png"
                  alt="Anna Montana"
                  className="rounded-circle me-3"
                  style={{ width: "50px", height: "50px" }}
                />
                <div>
                  <h5 className="mb-0">Anna Montana</h5>
                  <small>Product designer at Buffer</small>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={5} className="mb-4">
          <Card className="shadow border-0">
            <Card.Body>
              <Card.Text>
                I've tried several financial apps, but Fint is the only one that truly understands my goals. It's like having a personal financial advisor in my pocket. Highly recommend!
              </Card.Text>
              <div className="d-flex align-items-center mt-4">
                <img
                  src="/assets/avatar.png"
                  alt="Anna Montana"
                  className="rounded-circle me-3"
                  style={{ width: "50px", height: "50px" }}
                />
                <div>
                  <h5 className="mb-0">Anna Montana</h5>
                  <small>Product manager at Google</small>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* FAQ Section */}
      <Row className="faq text-center py-5 px-md-5">
        <Col>
          <h2 className="text-primary fw-bold mb-3">FAQ</h2>
          <h3 className="display-5 fw-bold">Frequently Asked Questions</h3>
        </Col>
      </Row>
      <Row className="justify-content-center py-3 px-md-5">
        <Col md={8}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>The Best Learning App Ever!</Accordion.Header>
              <Accordion.Body>
                Arcu At Dictum Sapien, Mollis. Vulputate Sit Id Accumsan, Ultricies. In Ultrices Malesuada Elit Mauris.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>The Best Learning App Ever!</Accordion.Header>
              <Accordion.Body>
                Arcu At Dictum Sapien, Mollis. Vulputate Sit Id Accumsan, Ultricies. In Ultrices Malesuada Elit Mauris.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>The Best Learning App Ever!</Accordion.Header>
              <Accordion.Body>
                Arcu At Dictum Sapien, Mollis. Vulputate Sit Id Accumsan, Ultricies. In Ultrices Malesuada Elit Mauris.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>The Best Learning App Ever!</Accordion.Header>
              <Accordion.Body>
                Arcu At Dictum Sapien, Mollis. Vulputate Sit Id Accumsan, Ultricies. In Ultrices Malesuada Elit Mauris.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>

      {/* Call-to-Action Section */}
      <Row className="cta text-white py-4 px-md-5" style={{ backgroundColor: '#0056b3', margin: '0 10%', height: '600px', display: 'flex', alignItems: 'center' }}>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="fw-bold text-center text-md-start">Ready To Get Started?</h2>
          <p className="lead text-center text-md-start">
            Risus Habitant Leo Egestas Mauris Diam Eget Morbi Tempus Vulputate.
          </p>
          <div className="d-flex justify-content-center justify-content-md-start gap-3 mt-4">
            <Button variant="light" className="p-0 border-0">
              <img src="/assets/playstore.png" alt="Google Play" className="img-fluid" />
            </Button>
            <Button variant="light" className="p-0 border-0">
              <img src="/assets/appstore.png" alt="App Store" className="img-fluid" />
            </Button>
          </div>
        </Col>
        <Col md={6} className="text-center">
          <img src="/assets/mid.png" alt="CTA Mockup" className="img-fluid" />
        </Col>
      </Row>
<br/>
<br/>
      {/* Footer Section */}
      <footer className="footer text-white py-5 px-md-5" style={{ backgroundColor: '#f8f9fa' }}>
        <Container>
          <Row className="align-items-center">
            <Col md={3} className="text-center text-md-start mb-4 mb-md-0">
              <img src="/assets/oasee-logo.png" alt="Oasee Logo" className="mb-3" style={{ width: '120px' }} />
              <p>Email: Help@Oasee.Com</p>
              <p>Phone: +1 234 456 678 89</p>
            </Col>
            <Col md={3} className="mb-4 mb-md-0">
              <h5 className="fw-bold text-dark">Links</h5>
              <ul className="list-unstyled">
                <li className="text-dark">Home</li>
                <li className="text-dark">About Us</li>
                <li className="text-dark">Features</li>
              </ul>
            </Col>
            <Col md={3} className="mb-4 mb-md-0">
              <h5 className="fw-bold text-dark">Legal</h5>
              <ul className="list-unstyled">
                <li className="text-dark">Terms Of Use</li>
                <li className="text-dark">Privacy Policy</li>
                <li className="text-dark">Cookie Policy</li>
              </ul>
            </Col>
            <Col md={3} className="text-center text-md-start">
              <h5 className="fw-bold text-dark">Newsletter</h5>
              <p className="text-dark">Stay Up To Date</p>
              <Form>
                <Form.Group className="d-flex">
                  <Form.Control type="email" placeholder="Your email" className="me-2" />
                  <Button variant="primary">Subscribe</Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <hr className="my-4" />
          <p className="text-center mb-0 text-dark">Copyright 2025 Oasee.ch All Rights Reserved</p>
        </Container>
      </footer>
    </Container>
  );
};

export default LandingPage;
